<template>
    <div id="notes">
        <v-toolbar class="sub-bar">
            <v-toolbar-title>
                <v-icon>fa fa-box</v-icon> Subscribe
            </v-toolbar-title>
        </v-toolbar>
        <v-row class="sub-cont" :no-gutters="$vuetify.breakpoint.smAndDown">
            <v-col cols="3" class="hidden-sm-and-down pr-0">
                <LeftNav />
            </v-col>
            <v-col cols="12" md="9">
                <v-card color="secondary" class="bg-wavelines-secondary-dark" dark :tile="$vuetify.breakpoint.smAndDown">
                    <v-card-text class="info-card pt-3">
                        <v-row dense class="align-center">
                            <v-col cols="7" md="12">
                                <p class="text-center lh-14 title mt-3 mb-0">
                                    <v-icon color="primary" class="mb-1" x-large>fa fa-box</v-icon>
                                    <br>
                                    <span class="hidden-sm-and-down">I'm Okay Subscription</span>
                                </p>
                                <p class="headline white--text text-center mt-1 mb-0 font-weight-bold">Only $3/month</p>
                            </v-col>
                            <v-col cols="5" md="6" class="text-left text-md-center hidden-md-and-up">
                                <v-divider class="hidden-sm-and-down" />
                                <p class="white--text subtitle-1 mt-1 mt-md-5 mb-1">
                                    <v-icon color="primary" small class="mr-1">fa fa-dot-circle</v-icon> No Contract
                                </p>
                                <p class="white--text subtitle-1 mb-1">
                                    <v-icon color="primary" small class="mr-1">fa fa-dot-circle</v-icon> Billed monthly
                                </p>
                                <p class="white--text subtitle-1 mb-1">
                                    <v-icon color="primary" small class="mr-1">fa fa-dot-circle</v-icon> Cancel Anytime
                                </p>
                            </v-col>
                            <v-col cols="12" class="text-center hidden-sm-and-down">
                                <p class="white--text subtitle-1 mb-1">
                                    No Contract
                                    <v-icon color="primary" small class="mx-2">fa fa-dot-circle</v-icon> Billed monthly
                                    <v-icon color="primary" small class="mx-2">fa fa-dot-circle</v-icon> Cancel Anytime
                                </p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card :loading="loading" class="elevation-1 mt-md-2" :tile="$vuetify.breakpoint.smAndDown">
                    <v-card-text v-if="user.email_verified_at">
                        <v-alert class="mt-2" dense type="error" v-if="errors">
                            {{ errors }}
                        </v-alert>
                        <v-card class="mx-auto mt-0 mt-md-8" max-width="500px">
                            <v-card-text>
                                <p class="title text-center lh-15 secondary--text mb-0">Start Your Subscription</p>
                                <p class="body-2 text-md-subtitle-1 lh-15 text-center secondary--text mb-3">Your first payment will be on {{ $moment.utc(user.trial_ends_at).tz(user.timezone).format('MMMM Do') }}.</p>
                                <v-text-field :autofocus="!$vuetify.breakpoint.smAndDown" v-show="!loading" v-model="name" label="Name on Credit Card" color="primary" />
                                <div class="my-3" id="card-elements"></div>
                                <v-divider v-show="!loading" style="border-color: rgba(0, 0, 0, 0.42);" />
                                <p class="text-center mt-5" v-show="!loading">
                                    <v-btn :disabled="!name.length || !cardReady" color="primary" :loading="saving" class="px-6" @click="verifyPayment()">Start Subscription</v-btn>
                                </p>
                                <p class="text-center mb-0" v-show="!loading">
                                    <v-btn v-if="!code" @click="enterCode=true" small text color="primary">Have a Promo Code?</v-btn>
                                    <span class="success--text font-weight-medium" v-else>Promo "{{ code }}" applied. <v-btn small icon color="error" @click.native="removePromo()"><v-icon small>fa fa-times-circle</v-icon></v-btn></span>
                                </p>
                            </v-card-text>
                        </v-card>
                        <template v-if="!loading">
                            <p class="caption text-center lh-11 px-0 px-md-10 mt-5">
                                You can update your payment details at any time. Payments securely processed by <a href="https://stripe.com" target="_blank">Stripe</a>. <br class="hidden-sm-and-down"> This application does not store nor have access to your credit card information.
                            </p>
                        </template>
                    </v-card-text>
                    <v-card-text v-else>
                        <v-card class="mx-auto mt-0 mt-md-8" max-width="500px">
                            <v-card-text>
                                <p class="title text-center secondary--text mb-0">Email Verification Required</p>
                                <p class="body-2 text-md-subtitle-1 text-center secondary--text mb-2">You must verify your email address before subscribing.</p>
                                <p class="body-2 text-md-subtitle-1 text-center secondary--text mb-3">
                                    <v-btn @click.native="verifyEmail=true" color="primary" rounded>Verify Your Email</v-btn>
                                </p>
                                <p class="body-2 text-md-subtitle-1 text-center secondary--text mb-5">You may need to refresh this page after verification.</p>                                
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <EmailVerify v-if="verifyEmail" @closed="verifyEmail=false"  />
        <SubscriptionPromoDialog v-if="enterCode" @closed="enterCode=false" @addCode="setCode($event)" />
    </div>
    
</template>
<script>
    
import { mapState } from 'vuex'
import LeftNav from '@/components/ui/LeftNav';

export default {
    name: 'UserSubscribe',
    components: {
        LeftNav,
        EmailVerify: () => import('@/views/auth/EmailVerify'),
        SubscriptionPromoDialog: () => import('@/components/user/profile/SubscriptionPromoDialog'),
    },
    computed: {
        ...mapState(['user']),
    },
    data: () => ({
        cardElement: null,
        cardReady: false,
        code: null,
        enterCode: false,
        errors: null,
        intent: null,
        loading: true,
        name: '',
        paymentId: null,
        promo: null,
        saving: false,
        stripe: null,
        source: null,
        stripeKey: 'pk_test_51IaZFNBhGwpEk6Y0fARagLQ08KL8v5iY4MRunKur0nC4kprp78LAAQMuoq5zvrlKuHHcAI6bwGGyhUdLPQi6CMDt00DQG3w5Tg',
        verifyEmail: false
    }),
    methods: {
        initData(){
            if(location.host == 'imokay.me'){
                this.stripeKey = 'pk_live_51IaZFNBhGwpEk6Y0cpnUm9XZYC7F4VeRbuXlmRxkZaAGYShgpwbsXdn7KC99XeYq7YZENLGXobnqctbzWU8h5fHP00cezt4KlU';
            }
            if(this.user.stripe_id){
                this.$router.push('/app/subscription');
            }
            this.getIntent();
            if(!this.user.email_verified_at){
                this.$appFn.trackEvent({ action: 'error', category: 'UserErrors', label: 'Tried to subscribe without email verification.'});
            }
            this.$appFn.trackEvent({action: 'begin_checkout', });
        },
        async verifyPayment(){
            this.errors = null;
            const { setupIntent, error } = await this.stripe.confirmCardSetup(
                this.intent.client_secret, {
                    payment_method: {
                        card: this.cardElement,
                        billing_details: { name: this.name }
                    }
                }
            );

            if (error) {
                // Display "error.message" to the user...
                this.errors = error.message;
            } else {
                // The card has been verified successfully...
                this.paymentId = setupIntent.payment_method;
                this.submitPayment();
            }
        },
        submitPayment(){
            this.saving = true;
            let request = {
                endpoint: '/subscribe', 
                method: 'post',
                data: {
                    paymentMethodId: this.paymentId,
                    promo: this.promo
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your subscription has been started!'
                    });
                    this.$appFn.trackEvent({action: 'purchase', value: 3.00,
                        coupon: this.code,
                        currency: 'USD',
                        transaction_id: res.data.id || null,                        
                        options: {
                            items: [{
                                item_id: 'SUB',
                                item_name: 'Monthly Subscription',
                                price: 3.00,
                                coupon: this.code
                            }]
                    }});
                    this.$eventHub.$emit('refreshUser');
                    this.$router.push('/app?subscribed');
                }
            }).catch(()=>{
            }).finally(()=>{
                this.saving = false;
            });
        },
        initStripe(){
            this.stripe = new window.Stripe(this.stripeKey);
            const elements = this.stripe.elements();
            this.cardElement = elements.create('card',{
                iconStyle: 'solid',
                style: {
                base: {
                    iconColor: '#e2195f',
                    color: '#000',
                    fontWeight: 400,
                    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',
                    borderBottom: '1px solid #ccc',
                    ':-webkit-autofill': {
                    color: '#e2195f',
                    },
                    '::placeholder': {
                    color: '#333',
                    },
                },
                invalid: {
                    iconColor: '#da3b4b',
                    color: '#da3b4b',
                },
                },
            });
            this.cardElement.mount('#card-elements');
            this.cardElement.on('change', (event) => {
                if (event.complete) {
                    // enable payment button
                    this.cardReady = true;
                } else if (event.error) {
                    this.cardReady = false;
                }
            });
        },
        getIntent(){ 
            this.loading = true;
            let request = {
                endpoint: '/getstripeintent', 
                method: 'get',
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.intent = res.data.intent;
                    // console.log(this.intent);
                    this.initStripe();
                }
            }).catch(()=>{
            }).finally(()=>{
                this.loading = false;
            });
        },
        setCode(data){
            this.code = data.code;
            this.promo = data.promoId;
            this.$appFn.trackEvent({action: 'select_promotion', options: {
                promotion_id: this.promo,
                promotion_name: this.code
            }});
        },
        removePromo(){
            this.code = null;
            this.promo = null;
        }
    },
    mounted(){
		this.initData();
	},
    watch: {
    }
}
</script>